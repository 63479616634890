import React from 'react';
import styled from 'styled-components';
import MapImage from '../../assets/images/map.png';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  margin: auto;
  padding: 50px 0;

  @media (min-width: 968px) {
    flex-direction: row;
    padding: 100px;
  }
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  gap: 20px;

  @media (min-width: 968px) {
    align-items: flex-start;
  }
`;

const Title = styled.h1`
  font-size: 2rem;
  font-weight: 700;
  margin-top: 10px;

  @media (min-width: 968px) {
    font-size: 3rem;
  }
`;

const SubTitle = styled.h3`
  font-size: 1.25rem;
  font-weight: 700;

  @media (min-width: 968px) {
    font-size: 1.5rem;
  }
`;

const Text = styled.p`
  margin-top: 10px;
`;

const Image = styled.img`
  width: 100%;
  height: auto;
  margin-top: 30px;

  @media (min-width: 968px) {
    width: 50%;
  }
`;

const ContactInfo: React.FC = () => {
  return (
    <Container>
      <InfoContainer>
        <div>
          <p>성공의 길로 안내합니다</p>
          <Title>찾아오시는 길</Title>
        </div>
        <Text>
          경기도 안양시 만안구덕천로 152번길 25 아이에즈비즈타워 B동 1506호
        </Text>
        <div style={{ marginTop: 50 }}>
          <SubTitle>인근 지하철</SubTitle>
          <Text>1호선 명학역 2번 출구에서 도보 5분</Text>
        </div>

        <div>
          <SubTitle>연락망</SubTitle>
          <Text>TEL : 010-6575-1161 / 031-444-1161</Text>
          <Text>FAX : 031-460-8553</Text>
          <Text>Email : cyj@lodong.co.kr</Text>
        </div>
      </InfoContainer>

      <Image src={MapImage} alt="map" />
    </Container>
  );
};

export default ContactInfo;
