import React from 'react';

//슬라이드 필요함
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { Route, Routes } from 'react-router-dom';
import GlobalStyle from './assets/css/GlobalStyle';

import {
  AboutUsUrl,
  BrochureUrl,
  CDNServiceUrl,
  ColocationUrl,
  ServerHostingUrl,
  SoftwareDevelopmentUrl,
  SoftwareDevelopment_Ai_Url,
  SoftwareDevelopment_Busan_Url,
  SoftwareDevelopment_Care_Url,
  SoftwareDevelopment_Cellpal_Url,
  SoftwareDevelopment_Company_Url,
  SoftwareDevelopment_FBMS_Url,
  SoftwareDevelopment_HRDLMSAPP_Url,
  SoftwareDevelopment_HRDLMS_Url,
  SoftwareDevelopment_Hyodong_Url,
  SoftwareDevelopment_KP_Url,
  SoftwareDevelopment_Keflavik_Url,
  SoftwareDevelopment_Lotto_Url,
  SoftwareDevelopment_Onfabric_Url,
  SoftwareDevelopment_RCE_Url,
  SoftwareDevelopment_RUGOLF_Url,
  SoftwareDevelopment_Share_Url,
  SoftwareDevelopment_Story_Url,
  SoftwareDevelopment_WashCube_Url,
  SoftwareDevelopment_WelcomeKorea_Url,
  homeUrl,
} from './url/LodongUrl';

import MainPage from './pages/MainPage';
import AboutUsPage from './pages/AboutUsPage';
import SoftwareDevelopmentPage from './pages/softwareDevelopment/SoftwareDevelopmentPage';
import CDNServicePage from './pages/cdnService/CDNServicePage';
import ServerHostingPage from './pages/serverHosting/ServerHostingPage';
import ColocationPage from './pages/colocation/ColocationPage';
import KeflavikPage from './pages/softwareDevelopment/SoftwareDevelopmentTabs/projectDetail/Keflavik/KeflavikPage';
import RuGolfPage from './pages/softwareDevelopment/SoftwareDevelopmentTabs/projectDetail/RUGOLF/RuGolfPage';
import WashPage from './pages/softwareDevelopment/SoftwareDevelopmentTabs/projectDetail/WashCube/WashPage';
import CarePage from './pages/softwareDevelopment/SoftwareDevelopmentTabs/projectDetail/Care/CarePage';
import AiPage from './pages/softwareDevelopment/SoftwareDevelopmentTabs/projectDetail/Ai/AiPage';
import LottoPage from './pages/softwareDevelopment/SoftwareDevelopmentTabs/projectDetail/Lotto/LottoPage';
import FBMSPage from './pages/softwareDevelopment/SoftwareDevelopmentTabs/projectDetail/FBMS/FBMSPage';
import WKoreaPage from './pages/softwareDevelopment/SoftwareDevelopmentTabs/projectDetail/WKorea/WKoreaPage';
import HyodongPage from './pages/softwareDevelopment/SoftwareDevelopmentTabs/projectDetail/Hyodong/HyodongPage';
import BusanPage from './pages/softwareDevelopment/SoftwareDevelopmentTabs/projectDetail/Busan/BusanPage';
import StoryPage from './pages/softwareDevelopment/SoftwareDevelopmentTabs/projectDetail/Story/StoryPage';
import CompanyPage from './pages/softwareDevelopment/SoftwareDevelopmentTabs/projectDetail/Company/CompanyPage';
import RCEPage from './pages/softwareDevelopment/SoftwareDevelopmentTabs/projectDetail/RCE/RCEPage';
import HRDLMSPage from './pages/softwareDevelopment/SoftwareDevelopmentTabs/projectDetail/HRDLMS/HRDLMSPage';
import HRDLMSAppPage from './pages/softwareDevelopment/SoftwareDevelopmentTabs/projectDetail/HRDLMS_APP/HRDLMSAppPage';
import OFPage from './pages/softwareDevelopment/SoftwareDevelopmentTabs/projectDetail/OF/OFPage';
import CellpalPage from './pages/softwareDevelopment/SoftwareDevelopmentTabs/projectDetail/Cellpal/CellpalPage';
import SharePage from './pages/softwareDevelopment/SoftwareDevelopmentTabs/projectDetail/Share/SharePage';
import KPPage from './pages/softwareDevelopment/SoftwareDevelopmentTabs/projectDetail/KP/KPPage';
import GoToTop from './components/GoToTop';
import BrochurePage from './pages/BrochurePage';
import NotFoundPage from './pages/NotFoundPage';

const App = () => {
  return (
    <>
      <GlobalStyle />
      <Routes>
        {/* 로동홈페이지 메인페이지 */}
        <Route path={homeUrl} element={<MainPage />} />
        <Route path={AboutUsUrl} element={<AboutUsPage />} />
        <Route
          path={SoftwareDevelopmentUrl}
          element={<SoftwareDevelopmentPage />}
        />
        {/* 프로젝트 페이지 */}
        <Route
          path={SoftwareDevelopment_Keflavik_Url}
          element={<KeflavikPage />}
        />
        <Route path={SoftwareDevelopment_RUGOLF_Url} element={<RuGolfPage />} />
        <Route path={SoftwareDevelopment_WashCube_Url} element={<WashPage />} />
        <Route path={SoftwareDevelopment_Care_Url} element={<CarePage />} />
        <Route path={SoftwareDevelopment_Ai_Url} element={<AiPage />} />
        <Route path={SoftwareDevelopment_Lotto_Url} element={<LottoPage />} />

        <Route path={SoftwareDevelopment_FBMS_Url} element={<FBMSPage />} />

        <Route
          path={SoftwareDevelopment_WelcomeKorea_Url}
          element={<WKoreaPage />}
        />
        <Route
          path={SoftwareDevelopment_Hyodong_Url}
          element={<HyodongPage />}
        />
        <Route path={SoftwareDevelopment_Busan_Url} element={<BusanPage />} />
        <Route path={SoftwareDevelopment_Story_Url} element={<StoryPage />} />
        <Route
          path={SoftwareDevelopment_Company_Url}
          element={<CompanyPage />}
        />
        <Route path={SoftwareDevelopment_RCE_Url} element={<RCEPage />} />
        <Route path={SoftwareDevelopment_HRDLMS_Url} element={<HRDLMSPage />} />
        <Route
          path={SoftwareDevelopment_HRDLMSAPP_Url}
          element={<HRDLMSAppPage />}
        />
        <Route path={SoftwareDevelopment_Onfabric_Url} element={<OFPage />} />
        <Route
          path={SoftwareDevelopment_Cellpal_Url}
          element={<CellpalPage />}
        />
        <Route path={SoftwareDevelopment_Share_Url} element={<SharePage />} />
        <Route path={SoftwareDevelopment_KP_Url} element={<KPPage />} />
        {/* 프로젝트 페이지 끝*/}
        <Route path={CDNServiceUrl} element={<CDNServicePage />} />
        <Route path={ServerHostingUrl} element={<ServerHostingPage />} />
        <Route path={ColocationUrl} element={<ColocationPage />} />
        <Route path={BrochureUrl} element={<BrochurePage />} />
        {/* 일치하는 경로가 없을 때 보여줄 404 페이지 */}
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
      <GoToTop />
    </>
  );
};
export default App;
